import Swiper, { Autoplay, FreeMode, Mousewheel, Navigation, Pagination } from 'swiper'
import React from 'react'
import config from '../config'

const handleHeroSwiper = () => {
  const heroSwiperImagesCount = Array.from(document.getElementsByClassName('hero-swiper__image'))?.length

  // eslint-disable-next-line no-new
  new Swiper('#home-hero-swiper', {
    modules: [FreeMode, Navigation, Autoplay, Pagination],
    slidesPerView: 1,
    spaceBetween: 0,
    freeMode: {
      enabled: false,
      momentum: false,
    },
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      bulletClass: 'swiper-pagination__bullet',
      bulletActiveClass: 'swiper-pagination__bullet--active',
      enabled: true,
      dynamicMainBullets: heroSwiperImagesCount || 6,
      dynamicBullets: true,
    },
    breakpoints: {
      768: {
        spaceBetween: 22,
        navigation: {
          nextEl: '.hero-swiper__navigation--next',
          prevEl: '.hero-swiper__navigation--prev',
          enabled: true,
        },
      },
    },
  })

  const heroSwiperSlides = Array.from(document.querySelectorAll('#home-hero-swiper .swiper-slide'))
  heroSwiperSlides.forEach((slide, index) => {
    slide.addEventListener('click', () => {
      // weird fix because of swiper loop active
      if (index > heroSwiperImagesCount) index = 1
      if (index === 0) index = heroSwiperImagesCount
      window.dataLayer.push({ event: `homepage-slider-${index}` })
    })
  })
}

const handleHomeSwipers = () => {
  const homeSliders = Array.from(document.getElementsByClassName('home-featured-block__content swiper'))

  const homeSlidersConfig = [
    {
      id: 'home-featured-swiper__tagged-products',
      freeMode: true,
      navigation: true,
      slidesPerView: 1.82,
      breakpoints: {
        550: {
          slidesPerView: 2.82,
          spaceBetween: 25,
          navigation: {
            enabled: true,
          },
        },
        1024: {
          slidesPerView: 5.2,
          spaceBetween: 25,
          navigation: {
            enabled: true,
          },
        },
      },
    },
    {
      id: 'home-featured-swiper__seller',
      freeMode: true,
      navigation: true,
      slidesPerView: 1.82,
      breakpoints: {
        550: {
          slidesPerView: 2.82,
          spaceBetween: 25,
          navigation: {
            enabled: true,
          },
        },
        1024: {
          slidesPerView: 5.2,
          spaceBetween: 25,
          navigation: {
            enabled: true,
          },
        },
      },
    },
    { id: 'home-featured-swiper__categories', freeMode: true },
    { id: 'home-featured-swiper__filtered-product-pages', freeMode: true },
    { id: 'home-featured-swiper__blog-articles', navigation: true, freeMode: true },
    { id: 'home-featured-swiper__brands', freeMode: true },
    { id: 'home-featured-swiper__press-articles', freeMode: true },
  ]

  /**
   * Get swiper config based on id
   * @param {HTMLElement} swiper
   * @returns {Object}
   */
  const getSwiperConfig = (swiper) => {
    const swiperCleanedId = swiper.id.split('--')[0]
    let conf = homeSlidersConfig.find((c) => c.id === swiperCleanedId)

    if (!conf) conf = {}

    if (conf.breakpoints) {
      Object.keys(conf.breakpoints).forEach((breakpoint) => {
        if (conf.breakpoints[breakpoint].navigation) {
          conf.breakpoints[breakpoint].navigation.nextEl = `#${swiper.id}-nav-next`
          conf.breakpoints[breakpoint].navigation.prevEl = `#${swiper.id}-nav-prev`
        }
      })
    }

    return conf
  }

  homeSliders.forEach((swiper) => {
    const conf = getSwiperConfig(swiper)

    // eslint-disable-next-line no-new
    new Swiper(`#${swiper.id}`, {
      modules: [FreeMode, Navigation, Autoplay, Mousewheel],
      slidesPerView: conf.slidesPerView || 'auto',
      spaceBetween: 11,
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      edgeSwipeDetection: 'prevent',
      freeMode: {
        enabled: conf.freeMode || false,
        momentumRatio: 0.8,
      },
      autoplay: conf.autoplay
        ? {
            delay: 5000,
          }
        : false,
      breakpoints: conf.breakpoints || {
        768: {
          spaceBetween: 22,
          navigation: {
            nextEl: `#${swiper.id}-nav-next`,
            prevEl: `#${swiper.id}-nav-prev`,
            enabled: conf.navigation || false,
          },
        },
      },
    })
  })
}

const handleHomeFeaturedBlockTagEvents = () => {
  const blocks = Array.from(document.getElementsByClassName('home-featured-block'))

  blocks?.forEach((block) => {
    const tag = block.getAttribute('data-tag-event')

    if (tag && tag !== '') {
      const button = block.querySelector('.home-featured-block__header-actions button')

      if (button)
        button.addEventListener('click', () => {
          window.dataLayer.push({ event: tag })
        })

      const slides = Array.from(block.querySelectorAll('.home-featured-block__content .swiper-slide'))

      slides?.forEach((slide) => {
        slide.addEventListener('click', () => {
          window.dataLayer.push({ event: tag })
        })
      })
    }
  })
}

const loadOneyCGVWidget = () => {
  const options = {
    country: 'FR',
    language: 'FR',
    merchant_guid: `${config.oney.merchantGUID}`,
    payment_amount: 2560.0,
    free_bts: true,
    showAsModal: false,
    merchant_name: 'CAMPSIDER',
    merchant_address: '132 CHEMIN DE MONTRAY 69110 SAINTE-FOY-LES-LYON',
    merchant_orias_number: '07 023 261',
    document_type: '10x12xCB',
    responseType: 'html',
  }
  const oneyLogo = document.getElementById('js_oney_cgv_trigger')

  const showOneyModal = (res) =>
    window.showElementsAsync('react-modals-container', 'bullshit-oney-comp', 'Modals/common/CommonModal', {
      html: res.html,
      title: 'GCV Oney',
      titleRightItem: <i className="icon--shield-filled has-text-reinsurance" />,
    })

  options.successCallback = (status, response) => {
    showOneyModal(response)
    oneyLogo.style.cursor = 'pointer'
  }
  options.errorCallback = (status, response) => {
    // eslint-disable-next-line no-console
    console.log(`${status} - ${response}`)
  }

  if (oneyLogo) {
    oneyLogo.style.cursor = 'pointer'
    oneyLogo.addEventListener('click', () => {
      oneyLogo.style.cursor = 'wait'
      /* global loadOneyWidget, oneyMerchantApp */
      loadOneyWidget(() => {
        /** @var {function} loadGeneralConditions */
        oneyMerchantApp.loadGeneralConditions({ options })
      })
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  handleHeroSwiper()
  handleHomeSwipers()
  handleHomeFeaturedBlockTagEvents()
  loadOneyCGVWidget()
})
